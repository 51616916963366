<template>
  <div>
    <b-form class="auth-login-form mt-2">
      <b-form-group label="Email" label-for="login-email">
        <b-form-input id="login-email"
          v-model="loginForm.email"
          name="login-email"
          placeholder="Email"
          :state="emailErrors.length > 0 ? false:null"
          @input="$v.loginForm.email.$touch()"/>
        <small class="text-danger">{{ emailErrors[0] }}</small>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between">
          <label for="login-password">Contraseña</label>
          <b-link :to="{name:'forgot-password'}">
            <small>¿Olvidaste tu contraseña?</small>
          </b-link>
        </div>
        <b-input-group
          class="input-group-merge"
          :class="passwordErrors.length > 0 ? 'is-invalid':null">
          <b-form-input id="login-password"
            v-model="loginForm.password"
            class="form-control-merge"
            :type="passwordFieldType"
            name="login-password"
            placeholder="············"
            :state="passwordErrors.length > 0 ? false:null" 
            @input="$v.loginForm.password.$touch()"/>
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility()"/>
            </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ passwordErrors[0] }}</small>
      </b-form-group>
      <!--
      <b-form-group>
        <b-form-checkbox
          id="remember-me"
          v-model="status"
          name="checkbox-1">
            Recordar Contraseña
        </b-form-checkbox>
      </b-form-group>
      -->
      <div>
        <b-overlay
          no-wrap
          :show="overlayForm"
          opacity="0.1"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="_onHidden()"/>
        <b-button
          block
          ref="button"
          :disabled="overlayForm"
          variant="primary"
          @click.prevent="_signIn()">
          <feather-icon
            icon="LogInIcon"
            class="mr-50"/>
          <span class="align-middle">
            Ingresar
          </span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
  import {mapActions,mapState} from 'vuex'
  import {required,email} from 'vuelidate/lib/validators'
  import {togglePasswordVisibility} from '@core/mixins/ui/forms'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default{
    name: 'LoginForm',
    data() {
      return {
        overlayForm: false,
        status: '',
        loginForm: {
          email: '',
          password: '',
        },
        checkbox_remember_me: false,
        errorAuth: ''
      }
    },
    mixins: [togglePasswordVisibility],
    validations: {
      loginForm: {
        email: {
          required,
          email
        },
        password: {
          required,
        },
      },
    },
    computed: {
      emailErrors () {
        const errors = []
        if (!this.$v.loginForm.email.$dirty) return errors
        !this.$v.loginForm.email.required && errors.push('El email es obligatorio')
        !this.$v.loginForm.email.email && errors.push('El email debe ser válido')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.loginForm.password.$dirty) return errors
        !this.$v.loginForm.password.required && errors.push('La contraseña es obligatoria')
        return errors
      },
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    methods: {
      ...mapActions('auth',['signIn']),
      ...mapActions('profile',['fetchProfile']),
      async _signIn () {
        this.$v.loginForm.$touch()
          if (!this.$v.loginForm.$invalid) {
            this.overlayForm = true
            await this.signIn(this.loginForm)
            .then(ressigin => {
              this.fetchProfile()
              .then(res => { 

                this.$PermissionHelper.setPermissions(res.data.me.permissions);
                this.$PermissionHelper.setRole(res.data.me.roles);

                if(res.data.me.roles[0].name === 'PreapprovalUser'){
                  this.$router.push({path: '/preapprovals'}).catch(err => {console.info(err.message)})
                }else{
                  if(res.data.me.roles[0].name === 'NewsUser'){
                    this.$router.push({path: '/news'}).catch(err => {console.info(err.message)})
                  } else{
                    this.$router.push({path: '/home'}).catch(err => {console.info(err.message)})
                  }
                }

                
                this.$root.$emit('fetch-profile-post-actions',res);
              })
            }).catch(err => {
              this._messageErrorAuth(err.status)
              this.$toast({
                component: ToastificationContent,
                  props: {
                    title: this.errorAuth,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
              })
            }).finally(() => {
              this.overlayForm = false
            });
          }
      },
      _messageErrorAuth(err) {
        switch (err) {
          case 400:
            return this.errorAuth = 'Estas credenciales no coinciden con nuestros registros'
          case 401:
            return this.errorAuth = 'Error no autorizado'
          case 403:
            return this.errorAuth = 'Error acceso denegado'
          case 404:
            return this.errorAuth = 'Error página no encontrada'
          case 500:
            return this.errorAuth = 'Error en el Servidor'
          default:
            return this.errorAuth = 'Error'
        }
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>

